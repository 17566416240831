import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import TagsInput from "react-tagsinput";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import Cropper from "../Cropper";
import api from "../../Axios";
import Select from "react-select";
import { customStyles } from "../../assets/css/CustomStyles";
import NotificationAlert from "react-notification-alert";

const AddVariant = () => {
  const notiRef = useRef();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    productId: "",
    productName: "",
    variantName: "",
    variantImage: [],
    tags: [],
    variantColor: "#000",
    actualPrice: [],
    discountPrice: [],
    productSpecification: [],

    tax: "",
  });
  const [variantcount, setVariantCount] = useState(1);
  const [productList, setProductList] = useState([]);
  const [render, setRender] = useState(false);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: type == "danger" ? 5 : 3,
    };
    notiRef.current.notificationAlert(options);
  }
  const [id, setId] = useState("");
  //---------- PRODUCT DETAILS --------
  useEffect(() => {
    api
      .get("/admin/product/getProduct/" + null)
      .then((res) => {
        if (Array.isArray(res.data.data))
          setProductList(
            res.data.data.map((data) => {
              return {
                label: data.productName,
                productId: data.id,
                tax: data.tax,
                productCategoryId: data.categoryId,
              };
            })
          );
      })
      .catch((error) => console.log("error", error));
  }, []);
  console.log(productList.productCategoryId);
  function setTags(e) {
    console.log(e);
    setData({ ...data, tags: e });
  }

  function handleChange(e) {
    if (e.label) {
      setData({
        ...data,
        productId: e.productId,
        productName: e.label,
        tax: e.tax,
      });
      setId(e.productCategoryId);
      setRender(!render); // Set productCategoryId to state
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  }
  console.log(id);
  function handleArrayChange(e) {
    if (e.color) {
      console.log(e.color);
      data.variantColor = e.color;
      setData({ ...data, variantColor: data.variantColor });
    } else if (e.target) {
      data[e.target.name] = e.target.value;
      setData({ ...data, [e.target.name]: data[e.target.name] });
    } else {
      data["tags"] = e;
      console.log(e);
      setData({ ...data, tags: data["tags"] });
    }
  }

  function handleImageChange(i, e) {
    data["variantImage"][i] = e;
    // console.log(e);
    setData({ ...data, variantImage: data["variantImage"] });
  }

  function handSubmit(e) {
    setLoad(true);
    e.preventDefault();
    e.currentTarget.reset();
    // console.log("data", data);
    const final = {
      ...data,
      productSpecification: JSON.stringify(data.productSpecification),
    };
    const formdata = new FormData();
    Object.entries(final).map((dat) => {
      formdata.append(dat[0], dat[1]);
    });
    api
      .post("/admin/product/addVariant", data)
      .then((res) => {
        setLoad(false);
        console.log(res.data.data);
        notify(res.data.data, "success");
        setRender(!render);
        setData({
          tags: [],
          color: [],
          actualPrice: [],
          discountPrice: [],
        });
        setVariantCount(1);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
        notify(err, "danger");
      });
  }

  const [valueList, setValueList] = useState([]);
  useEffect(() => {
    if (render) {
      setRender(false);
      api
        .post("/admin/product/title", { categoryId: id })
        .then((res) => {
          console.log("value List", res.data.data);
          if (res.data.data == "No Details Found") return setValueList([]);
          else {
            setValueList(
              res.data.data.map((datas, i) => {
                console.log("info", JSON.parse(datas.productTitle));
                JSON.parse(datas.productTitle).map(
                  (info, i) =>
                    (data.productSpecification[i] = {
                      productTitle: info,
                      productAnswer: "",
                    })
                );
                return {
                  productTitle: datas.productTitle,
                  categoryId: datas.categoryId,
                };
              })
            );
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [render]);
  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Product Variant</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Add Product Variant</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handSubmit}>
                        <FormGroup>
                          <Label htmlFor="exampleSelect3">Product Name</Label>
                          <Select
                            name="productName"
                            required
                            className="select2"
                            options={productList}
                            styles={customStyles}
                            onChange={handleChange}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleEmail7">Variant Name</Label>
                          <Input
                            type="text"
                            name="variantName"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleText">
                            SEO Tags{" "}
                            <span
                              style={{ color: "#ffb74d", fontWeight: "bold" }}
                            >
                              (Keywords used for searching products in website)
                            </span>
                          </Label>
                          <div className="form-group">
                            <div className="controls">
                              <TagsInput
                                value={data.tags}
                                name="tags"
                                onChange={setTags}
                                onlyUnique={true}
                                required
                              />
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="exampleText">Actual Price</Label>
                          <Input
                            type="text"
                            name="actualPrice"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleText">Discount Price</Label>
                          <Input
                            type="text"
                            name="discountPrice"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                            required
                          />
                          <FormGroup>
                            <Label htmlFor="exampleText">Color</Label>
                            <div
                              className="form-group"
                              style={{
                                textAlign: "left",
                              }}
                            >
                              <ColorPicker
                                defaultColor="#000"
                                required
                                animation="slide"
                                style={{ width: "" }}
                                onChange={(e) => handleArrayChange(e)}
                              />
                            </div>
                          </FormGroup>
                        </FormGroup>
                        {Array.from({ length: variantcount }, (x, i) => (
                          <>
                            {i > 0 ? <br /> : ""}
                            {/* <div className="col-lg-12 variant-border"> */}
                            <FormGroup>
                              <Label htmlFor="exampleFile">Variant Image</Label>{" "}
                              <span
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                {" "}
                                Dimensions : 1080 x 1080 ,{" "}
                              </span>{" "}
                              <span
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                {" "}
                                Size: Max 10mb
                              </span>
                              <Cropper
                                imageStore={handleImageChange}
                                index={i}
                                reset={render}
                                aspectRatio={1 / 1}
                              />
                            </FormGroup>

                            {/* </div> */}
                          </>
                        ))}

                        <FormGroup>
                          <div
                            className="btn btn-primary"
                            onClick={() => setVariantCount(variantcount + 1)}
                          >
                            +
                          </div>
                          {variantcount > 1 ? (
                            <div
                              className="btn btn-primary"
                              onClick={() => setVariantCount(variantcount - 1)}
                            >
                              -
                            </div>
                          ) : (
                            ""
                          )}
                        </FormGroup>
                        {valueList.map((info, index) =>
                          JSON.parse(info.productTitle).length > 0 ? (
                            <header className="panel_header">
                              <h2
                                className="title float-left"
                                style={{ paddingLeft: "0px" }}
                              >
                                Add Technical specifications
                              </h2>
                            </header>
                          ) : (
                            ""
                          )
                        )}

                        <div className="row">
                          {valueList.map((info, index) =>
                            JSON.parse(info.productTitle).map((datas, i) => (
                              <div className="col-lg-6">
                                <FormGroup>
                                  <Label
                                    htmlFor="exampleText"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {datas}
                                  </Label>
                                  <Input
                                    type="text"
                                    name="specificationValue"
                                    onChange={(e) => {
                                      // console.log(data.productSpecification)
                                      data.productSpecification[
                                        i
                                      ].productAnswer = e.target.value;
                                      // setRender(!renderScreen);
                                    }}
                                  />
                                </FormGroup>
                              </div>
                            ))
                          )}
                        </div>
                        {/* <FormGroup>
                          <Label htmlFor="exampleEmail7">Available Stock</Label>
                          <Input
                            type="text"
                            name="availableStock"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                            required
                          />
                        </FormGroup> */}

                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button type="submit" className="btn btn-primary">
                            {load ? (
                              <span>Loading...</span>
                            ) : (
                              <span>Submit</span>
                            )}
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* <ProductList /> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddVariant;
