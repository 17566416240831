import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import Datatable from "react-bs-datatable";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";

const Orders = () => {
  const notiRef = useRef();
  // const header = [
  //   { title: "ID", prop: "id", sortable: true, filterable: true },
  //   {
  //     title: "Profile Picture",
  //     prop: "profilePic",
  //     sortable: true,
  //     filterable: true,
  //   },
  //   { title: "Name", prop: "userName", sortable: true, filterable: true },
  //   { title: "Phone", prop: "phone", sortable: true, filterable: true },
  //   { title: "Gender", prop: "gender", sortable: true, filterable: true },
  //   { title: "Email", prop: "email", sortable: true, filterable: true },
  //   { title: "Status", prop: "status", sortable: true, filterable: true },
  //   { title: "Action", prop: "action", sortable: true, filterable: true },
  // ];

  const header = [
    { title: "ID", prop: "id", sortable: true, filterable: true },
    { title: "Order ID", prop: "orderId", sortable: true, filterable: true },
    {
      title: "Total Amount",
      prop: "totalAmount",
      sortable: true,
      filterable: true,
    },
    {
      title: "Paid Amount",
      prop: "paidAmount",
      sortable: true,
      filterable: true,
    },
    {
      title: "Payment Status",
      prop: "paymentStatus",
      sortable: true,
      filterable: true,
    },
    {
      title: "Delivery Type",
      prop: "deliveryType",
      sortable: true,
      filterable: true,
    },
    {
      title: "Order Status",
      prop: "orderStatus",
      sortable: true,
      filterable: true,
    },
    {
      title: "TimeStamp",
      prop: "txnTimeStamp",
      sortable: true,
      filterable: true,
    },
    { title: "Action", prop: "action", sortable: true, filterable: true },
  ];

  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display ",
    entries: " rows",
    noResults: "No Orders Found",
  };

  const [data, setData] = useState([]);
  const [render, setRerender] = useState(false);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };
    notiRef.current.notificationAlert(options);
  }

  function oderStatus(info, e) {
    console.log(info.id, e.target.value);
    api
      .post("/admin/orders/orderStatus", {
        orderId: info.orderId,
        orderStatus: e.target.value,
      })
      .then((res) => {
        console.log(res.data.data);
        setRerender(!render);
        notify(res.data.data, "success");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const getOrders = () => {
      api
        .get("/admin/orders/")
        .then((resp) => {
          Array.isArray(resp.data.data)
            ? setData(
                resp.data.data.map((data,i) => ({
                  ...data,
                  id:(
                    <span style={{ textTransform: "capitalize" }}>
                      {++i}
                    </span>
                  ),
                  txnTimeStamp: moment(data.txnTimeStamp).fromNow(),
                  paymentStatus: (
                    <span style={{ textTransform: "capitalize" }}>
                      {data.paymentStatus}
                    </span>
                  ),
                  orderStatus: (
                    <select
                      style={{ textTransform: "capitalize" }}
                      onChange={(e) => oderStatus(data, e)}
                    >
                      <option>{data.orderStatus}</option>
                      <option value="pending">Pending</option>
                      <option value="accepted">Accepted</option>
                      <option value="packaging">Packaging</option>
                      <option value="dispatched">Dispatched</option>
                      <option value="delivered">Delivered</option>
                      <option value="declined">Declined</option>
                    </select>
                  ),
                  action: (
                    <button
                      className="btn btn-secondary btn-icon bottom15 right15"
                      style={{ padding: "4px", fontSize: "13px" }}
                      onClick={() =>
                        (window.location.href = "/vieworders/" + data.orderId)
                      }
                    >
                      <label style={{ margin: "0px" }}>View Orders</label>
                    </button>
                  ),
                }))
              )
            : setData([]);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getOrders();
  }, [render]);

  function onRowClick(data) {
    alert(`You clicked on the row ${data.realname}`);
  }

  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Orders</h1>
              </div>
            </div>
            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Order Lists</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-lg-12 dt-disp">
                      <Datatable
                        onRowClick={onRowClick}
                        tableHeader={header}
                        tableBody={data}
                        keyName="userTable"
                        tableClass="striped table-hover table-responsive"
                        rowsPerPage={10}
                        rowsPerPageOption={[5, 10, 15, 20, 30]}
                        initialSort={{ prop: "id", isAscending: true }}
                        //   onSort={onSortFunction}
                        labels={customLabels}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Orders;
