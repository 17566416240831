import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import {} from "components";
// import BannerList from "./BannerList";
import Datatable from "react-bs-datatable";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import { customStyles } from "../../assets/css/CustomStyles";

const StockList = () => {
  const notiRef = useRef();
  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display ",
    entries: " rows",
    noResults: "There is no data to be displayed",
  };
  const header = [
    { title: "ID", prop: "id", sortable: true, filterable: true },
    {
      title: "Product Name",
      prop: "productName",
      sortable: true,
      filterable: true,
    },
    {
      title: "Tax Percentage",
      prop: "tax",
      sortable: true,
      filterable: true,
    },
    {
      title: "Variant Name",
      prop: "variantName",
      sortable: true,
      filterable: true,
    },
    {
      title: "Available Stock",
      prop: "availableStock",
      sortable: true,
      filterable: true,
    },
    {
      title: "Alternative Stock",
      prop: "alternateStock",
      sortable: true,
      filterable: true,
    },
    { title: "Action", prop: "action", sortable: true, filterable: true },
  ];

  const [data, setData] = useState({});
  const [productList, setProductList] = useState([]);
  const [render, setRender] = useState(false);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };
    notiRef.current.notificationAlert(options);
  }

  //----------- For Product Dropdown ------------------
  useEffect(() => {
    api.get("/admin/product/getProduct/" + null).then((res) => {
      if (Array.isArray(res.data.data))
        setProductList(
          res.data.data.map((data) => {
            return {
              label: data.productName,
              value: data.id,
            };
          })
        );
    });
  }, []);

  function handleChange(e) {
    if (e.label) {
      api
        .post("/admin/product/", {
          productId: e.value,
        })
        .then((res) => {
          console.log(res.data.data);
          setData(
            res.data.data.map((info) => ({
              ...info,
              productName: (
                <span style={{ textTransform: "capitalize" }}>
                  {info.productName}
                </span>
              ),
              variantName: (
                <span style={{ textTransform: "capitalize" }}>
                  {info.variantName}
                </span>
              ),
              action: (
                <button
                  className="btn btn-secondary"
                  style={{ padding: "8px", paddingRight: "10px" }}
                  onClick={() =>
                    (window.location.href = "/stockUpdate/" + info.id)
                  }
                >
                  <i className="i-pencil"></i> <span>Add Stock</span>
                </button>
              ),
            }))
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    console.log(data);
    const formdata = new FormData();
    Object.entries(data).map((data) => {
      formdata.append(data[0], data[1]);
    });

    api
      .post("/admin/shop/addBanners", formdata)
      .then((res) => {
        console.log(res.data.data);
        notify(res.data.data, "success");
        setRender(!render);
        setData("");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // console.log("CategoryList", data.bannerFor);

  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Stock Management</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Stock List</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <Label htmlFor="bannerType">Product Name</Label>
                          <Select
                            name="bannerType"
                            className="select2"
                            options={productList}
                            styles={customStyles}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Form>
                    </div>
                    <div className="col-lg-12 dt-disp mt-4">
                      <Datatable
                        tableHeader={header}
                        tableBody={data}
                        keyName="userTable"
                        tableClass="striped table-hover table-responsive"
                        rowsPerPage={10}
                        rowsPerPageOption={[5, 10, 15, 20, 30]}
                        initialSort={{ prop: "id", isAscending: true }}
                        //   onSort={onSortFunction}
                        labels={customLabels}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StockList;
