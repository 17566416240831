import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import ProductList from "./ProductList";
import Cropper from "../Cropper";
import TagsInput from "react-tagsinput";
import Select from "react-select";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import { customStyles } from "../../assets/css/CustomStyles";

const AddProduct = (props) => {
  const notiRef = useRef();
  const [taxlist, setTaxlist] = useState();
  const [load, setLoad] = useState(false);

  const [data, setData] = useState({
    categoryId: "",
    categoryName: "",
    tax: "",
    image: "",
    age: "",
    productName: "",
    customizationInputs: {},
    productDescription: "",
    productFormula: "8",
    customize: "",
    moreInfo: "",
    // productSpecification: [],
    tags: [],
  });

  const [customizationInputs, setCustomizationInputs] = useState({
    minLength: "",
    maxLength: "",
    minWidth: "",
    maxWidth: "",
    minHeight: "",
    maxHeight: "",
    lengthMiddle: "",
    lengthTop: "",
    lengthBottom: "",
    maxSheetLimit: "",
    midMatresHeight: "",
    maxSheetLimit: "",
    fittedMaxRate: "",
    flatMinRate: "",
    flatMaxRate: "",
  });

  const productFormula = [
    { value: "1", label: "Flat & Fitted Bedsheets", id: "productFormula" },
    { value: "2", label: "Mattress Protector", id: "productFormula" },
    { value: "3", label: "Pillow Protector", id: "productFormula" },
    { value: "4", label: "Mattress Comforter", id: "productFormula" },
    { value: "5", label: "Dohar", id: "productFormula" },
    { value: "6", label: "Fitted Bedsheets", id: "productFormula" },
    { value: "7", label: "Flat Bedsheets", id: "productFormula" },
    { value: "9", label: "Small Dohar", id: "productFormula" },
  ];

  const [catelist, setCateList] = useState();
  const [valueList, setValueList] = useState([]);
  const [render, setRender] = useState(false);
  const [submitRender, setSubmitRender] = useState(false);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };

    notiRef.current.notificationAlert(options);
  }
  // Get Tax
  useEffect(() => {
    const getTax = () => {
      api
        .post("/admin/shop/tax")
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data != "No Taxes found") {
            setTaxlist(
              res.data.data.map((data) => {
                return {
                  label: data.taxName,
                  value: data.id,
                  taxPercentage: data.taxPercentage,
                  taxId: data.id,
                };
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTax();
  }, [props.taxRender]);

  //-------------- GET CATEGORY ------------------
  useEffect(() => {
    api.get("/admin/product/getCategory").then((res) => {
      if (Array.isArray(res.data.data))
        setCateList(
          res.data.data.map((data) => {
            return {
              label: data.categoryName,
              value: data.id,
              id: data.id,
              tax: data.taxPercentage,
            };
          })
        );
    });
  }, []);
  //------------- SPEC --------------------
  // useEffect(() => {
  //   if (render) {
  //     setRender(false);
  //     api
  //       .post("/admin/product/title", { categoryId: data.categoryId })
  //       .then((res) => {
  //         console.log("value List", res.data.data);
  //         if (res.data.data == "No Details Found") return setValueList([]);
  //         else {
  //           setValueList(
  //             res.data.data.map((datas, i) => {
  //               console.log("info", JSON.parse(datas.productTitle));
  //               JSON.parse(datas.productTitle).map(
  //                 (info, i) =>
  //                   (data.productSpecification[i] = {
  //                     productTitle: info,
  //                     productAnswer: "",
  //                   })
  //               );
  //               return {
  //                 productTitle: datas.productTitle,
  //                 categoryId: datas.categoryId,
  //               };
  //             })
  //           );
  //         }
  //       })
  //       .catch((error) => console.log("error", error));
  //   }
  // }, [render]);

  function setTags(e) {
    console.log(e);
    setData({ ...data, tags: e });
  }

  const handle = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  function handleChanges(e) {
    if (e.label) {
      setData({ ...data, productFormula: e.value });
      //on change of formula, resetting the values
      setCustomizationInputs((pre) => ({
        minLength: "",
        maxLength: "",
        minWidth: "",
        maxWidth: "",
        minHeight: "",
        maxHeight: "",
        lengthMiddle: "",
        lengthTop: "",
        lengthBottom: "",
        maxSheetLimit: "",
        midMatresHeight: "",
        maxSheetLimit: "",
        fittedMaxRate: "",
        flatMinRate: "",
        flatMaxRate: "",
      }));
    }
  }

  function handleChanged(e) {
    if (e.label) {
      console.log(e);
      setData({
        ...data,
        taxId: e.taxId,
      });
    } else setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleChange(e) {
    if (e.label) {
      console.log(e);
      setData({
        ...data,
        categoryName: e.label,
        categoryId: e.id,
        tax: e.tax,
        age: e.age,
      });
      setRender(!render);
    } else setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleChange1(e) {
    setCustomizationInputs({
      ...customizationInputs,
      [e.target.name]: e.target.value,
    });
  }

  function handleImageChange(e) {
    setData({ ...data, image: e });
  }

  function handleSubmit(e) {
    setLoad(true);
    e.preventDefault();
    e.currentTarget.reset();
    const final = {
      ...data,
      // productSpecification: JSON.stringify(data.productSpecification),
      customizationInputs: JSON.stringify(customizationInputs),
    };

    console.log("final", final);

    const formdata = new FormData();
    Object.entries(final).map((data) => {
      formdata.append(data[0], data[1]);
    });

    api
      .post("/admin/product/addProduct", formdata)
      // .post("/admin/product/addProduct",{...data,customizationInputs:customizationInputs})
      .then((res) => {
        setLoad(false);
        console.log(res.data.data);
        notify(res.data.data, "success");
        // setprodDescription("");
        // setData({ productDescription: [] });
        // setData({tags:[]})
        setSubmitRender(!submitRender);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });
  }

  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Product</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Add Product</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <Label htmlFor="exampleSelect3">Category Name</Label>
                          <Select
                            name="cateName"
                            className="select2"
                            options={catelist}
                            styles={customStyles}
                            onChange={handleChange}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleEmail7">Product Name</Label>
                          <Input
                            type="text"
                            name="productName"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="exampleEmail7">Age : </Label>
                          <span style={{ color: "red" }}> Add Max Age</span>
                          <Input
                            type="text"
                            name="age"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="exampleSelect3">Tax Name</Label>
                          <Select
                            name="taxName"
                            className="select2"
                            options={taxlist}
                            styles={customStyles}
                            onChange={handleChanged}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="exampleText">
                            SEO Tags{" "}
                            <span
                              style={{ color: "#ffb74d", fontWeight: "bold" }}
                            >
                              (Keywords used for searching products in website)
                            </span>
                          </Label>
                          <div className="form-group">
                            <div className="controls">
                              <TagsInput
                                value={data.tags}
                                name="tags"
                                placeholder="Add SEO"
                                onChange={setTags}
                                onlyUnique={true}
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleFile">Product Image</Label>{" "}
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {" "}
                            Dimensions : 1080 x 1080 ,{" "}
                          </span>{" "}
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {" "}
                            Size: Max 10mb
                          </span>
                          <Cropper
                            imageStore={handleImageChange}
                            aspectRatio={1 / 1}
                            reset={submitRender}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Product Description
                          </Label>
                          <Input
                            type="textarea"
                            onChange={handleChange}
                            name="productDescription"
                            id="exampleText"
                          />
                        </FormGroup>
                        <Label htmlFor="exampleText">Is Customize?</Label>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="customize"
                              value="yes"
                              style={{
                                marginTop: "-10px",
                                marginBottom: "10px",
                              }}
                              onChange={handle}
                            />{" "}
                            Yes
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="customize"
                              value="no"
                              style={{ marginTop: "-10px" }}
                              onChange={handle}
                            />{" "}
                            No
                          </Label>
                        </FormGroup>

                        {data.customize === "yes" ? (
                          <FormGroup>
                            <Label htmlFor="exampleText">Product Formula</Label>
                            <Select
                              name="productFormula"
                              className="select2"
                              options={productFormula}
                              styles={customStyles}
                              onChange={handleChanges}
                            />
                          </FormGroup>
                        ) : (
                          false
                        )}

                        {/* customize forms list */}
                        {data.customize === "yes"
                          ? (() => {
                              switch (data.productFormula) {
                                case "1":
                                  return (
                                    <>
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="ddd">
                                              Min Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minLength"
                                              value={
                                                customizationInputs.minLength
                                              }
                                              id="ddd"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minWidth"
                                              value={
                                                customizationInputs.minWidth
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Height :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minHeight"
                                              value={
                                                customizationInputs.minHeight
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxLength"
                                              value={
                                                customizationInputs.maxLength
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxWidth"
                                              value={
                                                customizationInputs.maxWidth
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Height :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxHeight"
                                              value={
                                                customizationInputs.maxHeight
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Flat Min Rate :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="flatMinRate"
                                              value={
                                                customizationInputs.flatMinRate
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Flat Max Rate :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="flatMaxRate"
                                              value={
                                                customizationInputs.flatMaxRate
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        {/* <div className="col-lg-6">
                                <FormGroup>
                                <Label htmlFor="exampleEmail7">Fitted Min Rate : </Label>
                                <Input
                                  type="text"
                                  name="fittedMinRate"
                                  value={customizationInputs.fittedMinRate}
                                  id="exampleEmail7"
                                  placeholder=""
                                  onChange={handleChange1}
                                />
                              </FormGroup>
                                </div> */}
                                        <div className="col-lg-12">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Fitted Max Rate :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="fittedMaxRate"
                                              value={
                                                customizationInputs.fittedMaxRate
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Length Top :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="lengthTop"
                                              value={
                                                customizationInputs.lengthTop
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Length Middle :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              value={
                                                customizationInputs.lengthMiddle
                                              }
                                              name="lengthMiddle"
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Length Bottom :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="lengthBottom"
                                              value={
                                                customizationInputs.lengthBottom
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              MaxSheet Limit :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxSheetLimit"
                                              value={
                                                customizationInputs.maxSheetLimit
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              MidMatres Height :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="midMatresHeight"
                                              value={
                                                customizationInputs.midMatresHeight
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                    </>
                                  );
                                case "2":
                                  return (
                                    <>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minLength"
                                              value={
                                                customizationInputs.minLength
                                              }
                                              id="exampleEmail7"
                                              placeholder=""
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxLength"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxLength
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <FormGroup>
                                        <Label htmlFor="exampleEmail7">
                                          Max Rate :{" "}
                                        </Label>
                                        {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                        <Input
                                          type="text"
                                          name="maxRate"
                                          id="exampleEmail7"
                                          placeholder=""
                                          value={customizationInputs.maxRate}
                                          onChange={handleChange1}
                                        />
                                      </FormGroup>
                                    </>
                                  );
                                case "3":
                                  return (
                                    <>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minLength"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minLength
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxLength"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxLength
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <FormGroup>
                                        <Label htmlFor="exampleEmail7">
                                          Max Rate :{" "}
                                        </Label>
                                        {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                        <Input
                                          type="text"
                                          name="maxRate"
                                          id="exampleEmail7"
                                          placeholder=""
                                          value={customizationInputs.maxRate}
                                          onChange={handleChange1}
                                        />
                                      </FormGroup>
                                    </>
                                  );
                                case "4":
                                  return (
                                    <>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minLength"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minLength
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxLength"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxLength
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <FormGroup>
                                        <Label htmlFor="exampleEmail7">
                                          Max Rate :{" "}
                                        </Label>
                                        {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                        <Input
                                          type="text"
                                          name="maxRate"
                                          id="exampleEmail7"
                                          placeholder=""
                                          value={customizationInputs.maxRate}
                                          onChange={handleChange1}
                                        />
                                      </FormGroup>
                                    </>
                                  );
                                case "5":
                                  return (
                                    <>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Rate :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minRate"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minRate
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Rate :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxRate"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxRate
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                    </>
                                  );
                                case "6":
                                  return (
                                    <>
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minLength"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minLength
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Height :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minHeight"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minHeight
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxLength"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxLength
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Height :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxHeight"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxHeight
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Rate :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxRate"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxRate
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              MaxSheet Limit :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxSheetLimit"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxSheetLimit
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              MidMatres Height :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="midMatresHeight"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.midMatresHeight
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                    </>
                                  );
                                case "7":
                                  return (
                                    <>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minLength"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minLength
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Length :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxLength"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxLength
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Rate :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minRate"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minRate
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Rate :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxRate"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxRate
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Length Top :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="lengthTop"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.lengthTop
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Length Middle :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="lengthMiddle"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.lengthMiddle
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Length Bottom :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="lengthBottom"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.lengthBottom
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                    </>
                                  );
                                case "9":
                                  return (
                                    <>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Width :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxWidth"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxWidth
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Min Rate :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="minRate"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.minRate
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                          <FormGroup>
                                            <Label htmlFor="exampleEmail7">
                                              Max Rate :{" "}
                                            </Label>
                                            {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                                            <Input
                                              type="text"
                                              name="maxRate"
                                              id="exampleEmail7"
                                              placeholder=""
                                              value={
                                                customizationInputs.maxRate
                                              }
                                              onChange={handleChange1}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                    </>
                                  );
                                default:
                                  return "";
                              }
                            })()
                          : false}

                        <FormGroup>
                          <Label htmlFor="exampleText">
                            More Information{" "}
                            <span
                              style={{ color: "#ffb74d", fontWeight: "bold" }}
                            >
                              {" "}
                              (Optional)
                            </span>
                          </Label>
                          <Input
                            type="textarea"
                            onChange={handleChange}
                            name="moreInfo"
                          />
                        </FormGroup>
                        {/*---------------------- TECH SPEC--------------------------------------------  */}
                        {/* {valueList.map((info, index) =>
                          JSON.parse(info.productTitle).length > 0 ? (
                            <header className="panel_header">
                              <h2
                                className="title float-left"
                                style={{ paddingLeft: "0px" }}
                              >
                                Add Technical specifications
                              </h2>
                            </header>
                          ) : (
                            ""
                          )
                        )}

                        <div className="row">
                          {valueList.map((info, index) =>
                            JSON.parse(info.productTitle).map((datas, i) => (
                              <div className="col-lg-6">
                                <FormGroup>
                                  <Label
                                    htmlFor="exampleText"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {datas}
                                  </Label>
                                  <Input
                                    type="text"
                                    name="specificationValue"
                                    onChange={(e) => {
                                      // console.log(data.productSpecification)
                                      data.productSpecification[
                                        i
                                      ].productAnswer = e.target.value;
                                      // setRender(!renderScreen);
                                    }}
                                  />
                                </FormGroup>
                              </div>
                            ))
                          )}
                        </div> */}

                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button type="submit" className="btn btn-primary">
                            {load ? (
                              <span>Loading...</span>
                            ) : (
                              <span>Submit</span>
                            )}
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <ProductList render={submitRender} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddProduct;
