import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import api from "../../Axios";
import Pdf from "react-to-pdf";
import moment from "moment";
import Datatable from "react-bs-datatable";
import ColorPicker from "rc-color-picker";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import style from "../../assets/css/navtab.module.css";
import logo from "../../assets/img/bw.png";

const ref = React.createRef();
const ViewOrder = () => {
  const params = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const scrollContainerRef = useRef(null);
  // all data
  const [data, setData] = useState([]);

  const [courierName, setCourierName] = useState("");
  const [courierId, setCourierId] = useState("");

  // const[productName,se]
  const [table, setTable] = useState();
  const [courierIdData, setCourierIdData] = useState("");
  const [gst, setGST] = useState("");
  const [courierNameData, Data] = useState("");
  // const [inputData, setInputData] = useState({courierId:"",courierName:""});
  const [total, setTotal] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [cartData, setCartData] = useState([]);
  const [purchaseDate, setPurchaseDate] = useState();
  const [purchaseTime, setPurchaseTime] = useState();
  const [DeliveryDate, setDeliveryDate] = useState();
  const [render, setRender] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // var list=null;
  console.log(total);
  // function format(x) {
  //   return x.toString().split(",")[0].length > 3
  //     ? x
  //         .toString()
  //         .substring(0, x.toString().split(".")[0].length - 3)
  //         .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
  //         "," +
  //         x.toString().substring(x.toString().split(".")[0].length - 3)
  //     : x.toString();
  // }

  // const formatter = new Intl.NumberFormat("en-IN", {
  //   maximumSignificantDigits: 3,
  // });

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",

      width: "100%",
      height: "100%",
    },
    section: {
      textAlign: "center",
      margin: 10,
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    th: {
      borderBottom: "1px solid #000",
      padding: 8,
      textAlign: "left",
    },
    td: {
      borderBottom: "1px solid #000",
      padding: 8,
      textAlign: "left",
      textTransform: "capitalize",
    },
  });

  function handleSubmit(e) {
    e.preventDefault();

    api
      .post("/admin/orders/trackingInfo", {
        trackingInfo: courierId + "," + " " + courierName,
        orderId: params.id,
      })

      .then((res) => {
        Swal.fire({
          icon: "success",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: res.data.data,
        }).then((data) => {
          window.location.reload();
          // setTimeout(() => {
          //   setErr("");
          // }, 5000);
        });
        setCourierName("");
        setCourierId("");
        console.log(res.data.data);
        // notify(res.data.data, "success");
        // setprodDescription("");
        // setData({ productDescription: [] });
        // setData({tags:[]})
        // setSubmitRender(!submitRender);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: err.response.data.error.message,
        });
        console.log(err);
      });
  }

  const header = [
    { title: "ID", prop: "id", sortable: true, filterable: true },
    {
      title: "Variant Image",
      prop: "variantImage",
      sortable: true,
      filterable: true,
    },

    {
      title: "Product Name",
      prop: "productName",
      sortable: true,
      filterable: true,
    },
    {
      title: "Variant Name",
      prop: "variantName",
      sortable: true,
      filterable: true,
    },

    {
      title: "Total Amount",
      prop: "totalPrice",
      sortable: true,
      filterable: true,
    },
    {
      title: "Quantity",
      prop: "units",
      sortable: true,
      filterable: true,
    },

    // { title: "Timestamp", prop: "createdAt", sortable: true, filterable: true },
  ];

  const body = [
    {
      id: 1,
      name: "Helen Banks",
      company: "Brainlounge",
      email: "hbanks0@networkadvertising.org",
      phone: "386-(842)278-0044",
      date: moment().subtract(1, "days").format("Do MMMM YYYY"),
    },
    {
      id: 2,
      name: "Russell Wright",
      company: "Trilith",
      email: "rwright1@istockphoto.com",
      phone: "63-(139)594-8042",
      date: moment().subtract(2, "days").format("Do MMMM YYYY"),
    },
  ];

  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display ",
    entries: " rows",
    noResults: "There is no data to be displayed",
  };

  useEffect(() => {
    api
      .post("/admin/orders/orderDetails", { orderId: params.id })
      .then((res) => {
        const data = res.data.data;

        console.log("cart data", data.fetchCart.singleProductPrice);
        setData(data);
        setRender(!render);

        setTotal(data.billingInfo);
        setCartData(data.fetchCart);
        setPaymentStatus(data.fetchOrder.paymentStatus);

        const trackingInfo = data.fetchOrder.trackingInfo;
        if (typeof trackingInfo === "string") {
          const parts = trackingInfo.split(",");
          setCourierId(parts[0]);
          setCourierName(parts[1]);
        } else {
          console.error("Invalid trackingInfo:", trackingInfo);
          setCourierId(null);
          setCourierName(null);
        }

        setPurchaseDate(
          moment(data.fetchOrder.createdAt).format("MMMM D, YYYY, h:mm:ss a")
        );
        setPurchaseTime();
        setDeliveryDate(
          moment(data.fetchOrder.updatedAt).format("MMMM D, YYYY")
        );

        setTable(
          data.fetchCart.map((info, i) => ({
            ...info,
            id: i + 1,
            productName: (
              <span style={{ textTransform: "capitalize" }}>
                {info.productName}
              </span>
            ),
            variantImage: (
              <img
                src={process.env.REACT_APP_BASE_URL + info.variantImage}
                width={83}
                height={83}
                alt="Variant Image"
              />
            ),
            createdAt: moment(info.createdAt).format("MMM D ddd Y"),
            variantName: (
              <span style={{ textTransform: "capitalize" }}>
                {info.variantName}
              </span>
            ),
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //   console.log("cartsss", cartData);
  const handleCourierIdChange = (e) => {
    setCourierId(e.target.value);
  };

  const handleCourierNameChange = (e) => {
    setCourierName(e.target.value);
  };
  //   let data1 = datas[0];
  //   let data2 = datas[1];
  const bankData = [
    {
      // content: "\f47f",
      title: "Delivery Type",
      data: "deliveryType",
    },
    {
      // content: "\f47f",
      title: "Order Id ",
      data: "orderId",
    },
    {
      // content: "\f47f",
      title: "Order Status ",
      data: "orderStatus",
    },
    {
      // content: "\f47f",
      title: "Paid Amount ",
      data: "paidAmount",
    },
    {
      // content: "\f47f",
      title: "Payment Mode ",
      data: "paymentMethod",
    },
    {
      // content: "\f47f",
      title: "Payment Status ",
      data: "paymentStatus",
    },
    {
      // content: "\f47f",
      title: "Transaction Id ",
      data: "paymentTransactionId",
    },
    {
      // content: "\f47f",
      title: "Total Amount ",
      data: "totalAmount",
    },
    {
      // content: "\f47f",
      title: "Transaction Status ",
      data: "txnStatus",
    },
  ];

  function checkArray(data) {
    if (Array.isArray(data)) return data;
    else if (typeof data === "object") return [data];
    else return [];
  }
  console.log("data", checkArray(data));
  const formatters = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Order Details</h1>
              </div>
            </div>

            {/* Mid card */}
            <Nav tabs id={style.navPane} style={{ paddingBottom: "2px" }}>
              <NavItem>
                <NavLink
                  className="active"
                  id={style.tab5}
                  onClick={() => setActiveTab(1)}
                >
                  Order Item
                </NavLink>
              </NavItem>{" "}
              <NavItem>
                <NavLink
                  className="active"
                  id={style.tab1}
                  onClick={() => setActiveTab(2)}
                >
                  Order Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="active"
                  id={style.tab3}
                  onClick={() => setActiveTab(3)}
                >
                  Customer Profile Details
                </NavLink>
              </NavItem>{" "}
              <NavItem>
                <NavLink
                  className="active"
                  id={style.tab2}
                  onClick={() => setActiveTab(4)}
                >
                  Address Details
                </NavLink>
              </NavItem>{" "}
              <NavItem>
                <NavLink
                  className="active"
                  id={style.tab4}
                  onClick={() => setActiveTab(5)}
                >
                  Add Tracking Information
                </NavLink>
              </NavItem>{" "}
              <div
                className="col-12 "
                // style={{ display: "flex", justifyContent: "center" ,marginLeft:"-20px"}}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    color: "#fff",
                    backgroundColor: "#000",
                  }}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Generate Invoice
                </button>
              </div>
            </Nav>
            <TabContent
              activeTab={`${activeTab}`}
              style={{ backgroundColor: "none" }}
            >
              <TabPane tabId="1">
                <Row style={{ width: "100%" }}>
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12">
                      <section className="box ">
                        <div className="content-body">
                          <div className="row">
                            <div className="col-lg-12 dt-disp">
                              <Datatable
                                tableHeader={header}
                                tableBody={table}
                                keyName="userTable"
                                tableClass="striped table-hover table-responsive"
                                rowsPerPage={10}
                                rowsPerPageOption={[5, 10, 15, 20, 30]}
                                initialSort={{ prop: "id", isAscending: true }}
                                //   onSort={onSortFunction}
                                labels={customLabels}
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </Row>
              </TabPane>{" "}
              <TabPane tabId="2">
                {checkArray(data).map((info) => {
                  // just mapping to get info varaiable
                  return (
                    <Row style={{ width: "100%" }}>
                      <Col xs={12} md={6}>
                        <div className="page-title">
                          {data && data.fetchOrder.orderId ? (
                            <h4 style={{ fontWeight: "600" }}>Order ID</h4>
                          ) : (
                            false
                          )}
                          {data && data.fetchOrder.createdAt ? (
                            <h4 style={{ fontWeight: "600" }}>
                              Order Placed Date{" "}
                            </h4>
                          ) : (
                            false
                          )}
                          {data && data.fetchOrder.totalAmount ? (
                            <h4 style={{ fontWeight: "600" }}>Total Amount </h4>
                          ) : (
                            false
                          )}{" "}
                          {data && data.fetchOrder.paymentMethod ? (
                            <h4 style={{ fontWeight: "600" }}>
                              Payment Method
                            </h4>
                          ) : (
                            false
                          )}{" "}
                          {data && data.fetchOrder.paymentStatus ? (
                            <h4 style={{ fontWeight: "600" }}>
                              Payment Status
                            </h4>
                          ) : (
                            false
                          )}{" "}
                          {data && data.fetchOrder.orderStatus ? (
                            <h4 style={{ fontWeight: "600" }}>Order Status</h4>
                          ) : (
                            false
                          )}
                          {data &&
                          data.fetchOrder.trackingInfo.split(",")[0] ? (
                            <h4 style={{ fontWeight: "600" }}>Courier ID</h4>
                          ) : (
                            false
                          )}{" "}
                          {data &&
                          data.fetchOrder.trackingInfo.split(",")[1] ? (
                            <h4 style={{ fontWeight: "600" }}>Courier Name</h4>
                          ) : (
                            false
                          )}{" "}
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className="page-title">
                          <h4 style={{ textTransform: "capitalize" }}>
                            {data && data.fetchOrder.orderId}
                          </h4>
                          <h4 style={{ textTransform: "capitalize" }}>
                            {data &&
                              moment(data.fetchOrder.createdAt).format(
                                "DD-MM-YYYY"
                              )}
                          </h4>
                          <h4 style={{ textTransform: "capitalize" }}>
                            {data && data.fetchOrder.totalAmount}
                          </h4>
                          <h4 style={{ textTransform: "capitalize" }}>
                            {data && data.fetchOrder.paymentMethod}
                          </h4>
                          <h4 style={{ textTransform: "capitalize" }}>
                            {data && data.fetchOrder.paymentStatus}
                          </h4>
                          <h4 style={{ textTransform: "capitalize" }}>
                            {data && data.fetchOrder.orderStatus}
                          </h4>
                          <h4 style={{ textTransform: "capitalize" }}>
                            {data && data.fetchOrder.trackingInfo.split(",")[0]}
                          </h4>{" "}
                          <h4 style={{ textTransform: "capitalize" }}>
                            {data && data.fetchOrder.trackingInfo.split(",")[1]}
                          </h4>{" "}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </TabPane>
              <TabPane tabId="3">
                <div className="page-title">
                  {" "}
                  {checkArray(data).map((info) => {
                    // just mapping to get info varaiable
                    return (
                      <Row style={{ width: "100%" }}>
                        <Col xs={12} md={6}>
                          <div className="page-title">
                            {data && data.fetchUser.userName ? (
                              <h4 style={{ fontWeight: "600" }}>Name</h4>
                            ) : (
                              false
                            )}
                            {data && data.fetchUser.email ? (
                              <h4 style={{ fontWeight: "600" }}>Email </h4>
                            ) : (
                              false
                            )}
                            {data && data.fetchUser.phone ? (
                              <h4 style={{ fontWeight: "600" }}>
                                Phone Number
                              </h4>
                            ) : (
                              false
                            )}{" "}
                            {data && data.fetchUser.isMailVerified ? (
                              <h4 style={{ fontWeight: "600" }}>
                                Is Mail Verified
                              </h4>
                            ) : (
                              false
                            )}{" "}
                            {data && data.fetchUser.isPhoneVerified ? (
                              <h4 style={{ fontWeight: "600" }}>
                                Is Phone Number Verified
                              </h4>
                            ) : (
                              false
                            )}{" "}
                            {data && data.fetchUser.status ? (
                              <h4 style={{ fontWeight: "600" }}>
                                Customer Status
                              </h4>
                            ) : (
                              false
                            )}{" "}
                            {data && data.fetchUser.dob ? (
                              <h4 style={{ fontWeight: "600" }}>
                                Date of Birth
                              </h4>
                            ) : (
                              false
                            )}
                          </div>
                        </Col>{" "}
                        <Col xs={12} md={6}>
                          <div className="page-title">
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchUser.userName}
                            </h4>
                            <h4>{data && data.fetchUser.email}</h4>
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchUser.phone}
                            </h4>
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchUser.isMailVerified}
                            </h4>
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchUser.isPhoneVerified}
                            </h4>
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchUser.status}
                            </h4>{" "}
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data &&
                                moment(data.fetchUser.dob).format("DD-MM-YYYY")}
                            </h4>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="page-title">
                  {" "}
                  {checkArray(data).map((info) => {
                    // just mapping to get info varaiable
                    return (
                      <Row style={{ width: "100%" }}>
                        <Col xs={12} md={6}>
                          <div className="page-title">
                            {data && data.fetchAddress.addressType ? (
                              <h4 style={{ fontWeight: "600" }}>
                                Address Type
                              </h4>
                            ) : (
                              false
                            )}{" "}
                            {data && data.fetchAddress.street ? (
                              <h4 style={{ fontWeight: "600" }}>Street</h4>
                            ) : (
                              false
                            )}
                            {data && data.fetchAddress.landmark ? (
                              <h4 style={{ fontWeight: "600" }}>Landmark </h4>
                            ) : (
                              false
                            )}
                            {data && data.fetchAddress.city ? (
                              <h4 style={{ fontWeight: "600" }}>City</h4>
                            ) : (
                              false
                            )}{" "}
                            {data && data.fetchAddress.district ? (
                              <h4 style={{ fontWeight: "600" }}>District</h4>
                            ) : (
                              false
                            )}{" "}
                            {data && data.fetchAddress.state ? (
                              <h4 style={{ fontWeight: "600" }}>State</h4>
                            ) : (
                              false
                            )}{" "}
                            {data && data.fetchAddress.zipcode ? (
                              <h4 style={{ fontWeight: "600" }}>Zipcode</h4>
                            ) : (
                              false
                            )}{" "}
                          </div>
                        </Col>{" "}
                        <Col xs={12} md={6}>
                          <div className="page-title">
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchAddress.addressType}
                            </h4>{" "}
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchAddress.street}
                            </h4>
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchAddress.landmark}
                            </h4>
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchAddress.city}
                            </h4>
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchAddress.district}
                            </h4>
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchAddress.state}
                            </h4>
                            <h4 style={{ textTransform: "capitalize" }}>
                              {data && data.fetchAddress.zipcode}
                            </h4>{" "}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  {" "}
                  <div className="col-10 col-sm-5">
                    <Form onSubmit={handleSubmit}>
                      <h5>Add Tracking Information</h5>
                      <FormGroup>
                        <Input
                          type="text"
                          name="courierId"
                          id="exampleEmail7"
                          value={courierId}
                          placeholder="Enter Courier Id"
                          onChange={handleCourierIdChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="text"
                          name="courierName"
                          id="exampleEmail8"
                          value={courierName}
                          placeholder="Enter Courier Name"
                          onChange={handleCourierNameChange}
                        />
                      </FormGroup>
                      <FormGroup style={{ marginBottom: "0px" }}>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{
                            color: "#fff",
                            backgroundColor: "#000",
                          }}
                        >
                          Add tracking Info
                        </button>
                      </FormGroup>
                    </Form>
                  </div>
                </Row>
              </TabPane>
            </TabContent>
            {/* new details container */}
            <div className="row">
              <div className="col-xl-12 col-md-12 col-sm-12">
                <section className="box ">
                  {checkArray(data).map((info) => {
                    // just mapping to get info varaiable
                    return (
                      <div className="col-lg-4">
                        <div className="uprofile">
                          <div className="d-flex justify-content-center">
                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header>
                                <Modal.Title>Invoice</Modal.Title>
                                <Pdf
                                  targetRef={ref}
                                  filename={data.fetchOrder.orderId}
                                  size="A4"
                                >
                                  {({ toPdf }) => (
                                    <Button
                                      onClick={toPdf}
                                      style={{
                                        backgroundColor: "#000",
                                        color: "#fff",
                                      }}
                                    >
                                      Generate Pdf
                                    </Button>
                                  )}
                                </Pdf>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row">
                                  <div className="container">
                                    <div ref={ref}>
                                      <Document>
                                        <Page size="A5" style={styles.page}>
                                          <div>
                                            {/* Company details */}
                                            <div className="row text-center contact-info">
                                              <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                                <img
                                                  src={logo}
                                                  alt="Company Logo"
                                                  style={{
                                                    paddingBottom: "20px",
                                                  }}
                                                />
                                                <div>
                                                  <span
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {/* <strong>Email : </strong>  {info.fetchUser.email} &nbsp; */}
                                                    <strong>
                                                      GST Number :{" "}
                                                    </strong>{" "}
                                                    {info.gstInfo.gstNumber}{" "}
                                                    &nbsp;
                                                  </span>
                                                </div>{" "}
                                                <div>
                                                  <span
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {/* <strong>Email : </strong>  {info.fetchUser.email} &nbsp; */}
                                                    <strong>
                                                      Trade Name :{" "}
                                                    </strong>{" "}
                                                    {info.gstInfo.tradeName}{" "}
                                                    &nbsp;
                                                  </span>
                                                </div>{" "}
                                                <div>
                                                  <span
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {/* <strong>Email : </strong>  {info.fetchUser.email} &nbsp; */}
                                                    <strong>Address : </strong>{" "}
                                                    {info.gstInfo.address}{" "}
                                                    &nbsp;
                                                  </span>
                                                </div>
                                                {
                                                  //  <div>
                                                  //     <span
                                                  //       style={{ fontSize: "14px" }}
                                                  //     >
                                                  //       {/* <strong>Email : </strong>  {info.fetchUser.email} &nbsp; */}
                                                  //       <strong>Call : </strong>{" "}
                                                  //       {info.fetchUser.phone}{" "}
                                                  //       &nbsp;
                                                  //     </span>
                                                  //   </div>
                                                }
                                                <hr />
                                              </div>
                                            </div>
                                            {/* Customer & payment details */}
                                            <div className="row pad-top-botm client-info">
                                              <div
                                                className="col-sm-6"
                                                style={{
                                                  marginTop: "-20px",
                                                  padding: "25px",
                                                }}
                                              >
                                                <h4
                                                  style={{
                                                    textTransform: "uppercase",
                                                    fontSize: "16px",
                                                    lineHeight: "1.2",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Customer Information
                                                </h4>
                                                <ul
                                                  className="list-item-bold"
                                                  style={{
                                                    listStyle: "none",
                                                    paddingLeft: 0,
                                                  }}
                                                >
                                                  <li>
                                                    Name:{" "}
                                                    <span>
                                                      {data.fetchUser.userName}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    Street:{" "}
                                                    <span>{`${data.fetchAddress.street}, ${data.fetchAddress.landmark}, ${data.fetchAddress.city}, ${data.fetchAddress.district} - ${data.fetchAddress.zipcode}, ${data.fetchAddress.state}`}</span>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div
                                                className="col-sm-6"
                                                style={{
                                                  marginTop: "-20px",
                                                  padding: "25px",
                                                }}
                                              >
                                                <h4
                                                  style={{
                                                    textTransform: "uppercase",
                                                    fontSize: "16px",
                                                    lineHeight: "1.2",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Payment Details
                                                </h4>
                                                <ul
                                                  className="list-item-bold"
                                                  style={{
                                                    listStyle: "none",
                                                    paddingLeft: 0,
                                                  }}
                                                >
                                                  <li>
                                                    Invoice Number:{" "}
                                                    <span>
                                                      {data.fetchOrder.orderId}
                                                    </span>
                                                  </li>
                                                  {/* {     <li>
                                                    Payment Status:{" "}
                                                    <span>
                                                      {
                                                        data.fetchOrder
                                                          .paymentStatus
                                                      }
                                                    </span>
                                                  </li>} */}
                                                  <li>
                                                    Purchased on:{" "}
                                                    <span>
                                                      {moment(
                                                        data.fetchOrder
                                                          .createdAt
                                                      ).format(
                                                        "DD MMM YYYY hh:mm A"
                                                      )}
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                            {/* Product table details */}
                                            <div className="row">
                                              <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="table-responsive">
                                                  <table
                                                    style={styles.table}
                                                    className="table table-striped table-bordered table-hover"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th style={styles.th}>
                                                          Product
                                                        </th>
                                                        <th style={styles.th}>
                                                          Quantity
                                                        </th>
                                                        <th style={styles.th}>
                                                          Unit Price
                                                        </th>
                                                        <th style={styles.th}>
                                                          Sub Total
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {cartData.map((item) => (
                                                        <tr key={item.id}>
                                                          <td style={styles.td}>
                                                            {item.productName}
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                                marginLeft:
                                                                  "10px",
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              (
                                                              {item.variantName}
                                                              )
                                                            </span>
                                                          </td>
                                                          <td
                                                            style={{
                                                              ...styles.td,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {item.units}
                                                          </td>
                                                          <td
                                                            style={{
                                                              ...styles.td,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {
                                                              item.singleProductPrice
                                                            }
                                                          </td>
                                                          <td
                                                            style={{
                                                              ...styles.td,
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {item.totalPrice}
                                                          </td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <hr />
                                                <h5>
                                                  GST Amount: ₹
                                                  {total &&
                                                    total.gst.toFixed(2)}
                                                </h5>
                                                <h5>
                                                  Total Amount: ₹
                                                  {total && total.totalPrice}
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </Page>
                                      </Document>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </section>
              </div>
            </div>
            {/* Mid card end */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewOrder;
