import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import Cropper from "../Cropper";
import TagsInput from "react-tagsinput";
import Select from "react-select";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import { customStyles } from "../../assets/css/CustomStyles";

const AddProduct = (props) => {
  const notiRef = useRef();
  const params = useParams();
  const [load, setLoad] = useState(false);
  const productId = params.id;
  const handle = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  function handleChanged(e) {
    if (e.label) {
      console.log(e);
      setData({
        ...data,
        taxId: e.taxId,
      });
    } else setData({ ...data, [e.target.name]: e.target.value });
  }
  const [data, setData] = useState({
    categoryId: "",
    categoryName: "",
    tax: "",
    productName: "",
    age: "",
    productDescription: "",
    customize: "",
    customizationInputs: {},
    moreInfo: "",
    // productSpecification: [],
    tags: [],
  });
  const [customizationInputs, setCustomizationInputs] = useState({
    minLength: "",
    maxLength: "",
    minWidth: "",
    maxWidth: "",
    minHeight: "",
    maxHeight: "",
    lengthMiddle: "",
    lengthTop: "",
    lengthBottom: "",
    maxSheetLimit: "",
    midMatresHeight: "",
    fittedMinRate: "",
    fittedMaxRate: "",
    flatMinRate: "",
    flatMaxRate: "",
  });
  const [catelist, setCateList] = useState();
  const [tag, setTag] = useState([]);
  const [taxlist, setTaxlist] = useState();
  const [valueList, setValueList] = useState();
  const [render, setRender] = useState(false);
  const [renderScreen, setRenderScreen] = useState(false);
  const [submitRender, setSubmitRender] = useState(false);
  const [convertedImage, setConvertedImage] = useState("");
  const [GetImage, SetGetImage] = useState("");
  const [spec, setSpec] = useState(false);
  const [loading, setLoading] = useState(true);

  // let productFormula = [
  //   { value: "1", label: "Flat & Fitted Bedsheets", id: "productFormula" },
  //   { value: "2", label: "Mattress Protector", id: "productFormula" },
  //   { value: "3", label: "Pillow Protector", id: "productFormula" },
  //   { value: "4", label: "Mattress Comforter", id: "productFormula" },
  //   { value: "5", label: "Dohar", id: "productFormula" },
  //   { value: "6", label: "Fitted Bedsheets", id: "productFormula" },
  //   { value: "7", label: "Flat Bedsheets", id: "productFormula" },

  // ];
  const [productFormula, setProductFormula] = useState("");
  function handleChanges(e) {
    if (e.label) {
      console.log("files", e);
      setData({ ...data, productFormula: e.label, id: e.id });
      setRender(!render);
      // e.id == "prodcate"
      //   ? setData({ ...data, productOrCategoryId: e.finalId })
      //   : setData({ ...data, bannerType: e.value });
    }
    // setData({...data, bannerType:e.value })
    else setData({ ...data, [e.target.name]: e.target.value });
  }
  function handleChange1(e) {
    setCustomizationInputs({
      ...customizationInputs,
      [e.target.name]: e.target.value,
    });
  }
  console.log("customizationData", customizationInputs);

  function SwitchCase1(props) {
    switch (props.value) {
      case "1":
        return "Flat & Fitted Bedsheets";
      case "2":
        return "Mattress Protector";
      case "3":
        return "Pillow Protector";
      case "4":
        return "Mattress Comforter";
      case "5":
        return "Dohar";
      case "6":
        return "Fitted Bedsheets";
      case "7":
        return "Flat Bedsheets";
      default:
        return "";
    }
  }

  function SwitchCase(props) {
    switch (props.value) {
      case "1":
        return (
          <>
            <div className="row">
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minLength"
                    value={customizationInputs.minLength}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minWidth"
                    value={customizationInputs.minWidth}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Height : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minHeight"
                    value={customizationInputs.minHeight}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxLength"
                    value={customizationInputs.maxLength}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxWidth"
                    value={customizationInputs.maxWidth}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Height : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxHeight"
                    value={customizationInputs.maxHeight}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Flat Min Rate : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="flatMinRate"
                    value={customizationInputs.flatMinRate}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Flat Max Rate : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="flatMaxRate"
                    value={customizationInputs.flatMaxRate}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Fitted Min Rate : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="fittedMinRate"
                    value={customizationInputs.fittedMinRate}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Fitted Max Rate : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="fittedMaxRate"
                    value={customizationInputs.fittedMaxRate}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Length Top : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="lengthTop"
                    value={customizationInputs.lengthTop}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Length Middle : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    value={customizationInputs.lengthMiddle}
                    name="lengthMiddle"
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Length Bottom : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="lengthBottom"
                    value={customizationInputs.lengthBottom}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">MaxSheet Limit : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxSheetLimit"
                    value={customizationInputs.maxSheetLimit}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">MidMatres Height : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="midMatresHeight"
                    value={customizationInputs.midMatresHeight}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
          </>
        );
      case "2":
        return (
          <>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minLength"
                    value={customizationInputs.minLength}
                    id="exampleEmail7"
                    placeholder=""
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxLength"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxLength}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <FormGroup>
              <Label htmlFor="exampleEmail7">Max Rate : </Label>
              {/* <span style={{color:"red"}}> Add Min Rate</span> */}
              <Input
                type="text"
                name="maxRate"
                id="exampleEmail7"
                placeholder=""
                value={customizationInputs.maxRate}
                onChange={handleChange1}
              />
            </FormGroup>
          </>
        );
      case "3":
        return (
          <>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minLength"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minLength}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxLength"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxLength}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <FormGroup>
              <Label htmlFor="exampleEmail7">Max Rate : </Label>
              {/* <span style={{color:"red"}}> Add Min Rate</span> */}
              <Input
                type="text"
                name="maxRate"
                id="exampleEmail7"
                placeholder=""
                value={customizationInputs.maxRate}
                onChange={handleChange1}
              />
            </FormGroup>
          </>
        );
      case "4":
        return (
          <>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minLength"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minLength}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxLength"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxLength}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <FormGroup>
              <Label htmlFor="exampleEmail7">Max Rate : </Label>
              {/* <span style={{color:"red"}}> Add Min Rate</span> */}
              <Input
                type="text"
                name="maxRate"
                id="exampleEmail7"
                placeholder=""
                value={customizationInputs.maxRate}
                onChange={handleChange1}
              />
            </FormGroup>
          </>
        );
      case "5":
        return (
          <>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Rate : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minRate"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minRate}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Rate : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxRate"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxRate}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
          </>
        );
      case "6":
        return (
          <>
            <div className="row">
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minLength"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minLength}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Height : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minHeight"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minHeight}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxLength"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxLength}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Height : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxHeight"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxHeight}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Rate : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxRate"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxRate}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">MaxSheet Limit : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxSheetLimit"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxSheetLimit}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">MidMatres Height : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="midMatresHeight"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.midMatresHeight}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
          </>
        );
      case "7":
        return (
          <>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minLength"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minLength}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Length : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxLength"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxLength}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Width : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxWidth"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxWidth}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Min Rate : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="minRate"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.minRate}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-6">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Max Rate : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="maxRate"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.maxRate}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Length Top : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="lengthTop"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.lengthTop}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Length Middle : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="lengthMiddle"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.lengthMiddle}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup>
                  <Label htmlFor="exampleEmail7">Length Bottom : </Label>
                  {/* <span style={{color:"red"}}> Add Min Rate</span> */}
                  <Input
                    type="text"
                    name="lengthBottom"
                    id="exampleEmail7"
                    placeholder=""
                    value={customizationInputs.lengthBottom}
                    onChange={handleChange1}
                  />
                </FormGroup>
              </div>
            </div>
          </>
        );
      default:
        return "";
    }
  }

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };
    notiRef.current.notificationAlert(options);
    var redirect =
      type === "success"
        ? setTimeout(() => (window.location.href = "/product"), 1500)
        : "";
  }

  //-------------- GET CATEGORY AND PRODUCT------------------
  useEffect(() => {
    // ------ CATEGORY ------------
    api.get("/admin/product/getCategory").then((res) => {
      if (Array.isArray(res.data.data))
        setCateList(
          res.data.data.map((data) => {
            return {
              label: data.categoryName,
              value: data.id,
              id: data.id,
              tax: data.taxPercentage,
            };
          })
        );
    });

    // ----- PRODUCT ----------
    api.get("/admin/product/getProduct/" + productId).then((res) => {
      // console.log(JSON.parse(res.data.data.tags).split(","));
      setTag(JSON.parse(res.data.data.tags).split(","));
      setData({
        ...data,
        ...res.data.data,
        customize: res.data.data.customize,
      });
      if (Array.isArray(res.data.data))
        setProductFormula(
          res.data.data.map((data) => {
            return {
              label: <SwitchCase1 value={data.productFormula} />,

              value: data.id,
              id: data.id,
            };
          })
        );
      if (res.data.data.customizationInputs) {
        setCustomizationInputs({
          // ...data,
          // ...res.data.data,
          minLength: JSON.parse(res.data.data.customizationInputs).minLength,
          maxLength: JSON.parse(res.data.data.customizationInputs).maxLength,
          minWidth: JSON.parse(res.data.data.customizationInputs).minWidth,
          maxWidth: JSON.parse(res.data.data.customizationInputs).maxWidth,
          minHeight: JSON.parse(res.data.data.customizationInputs).minHeight,
          maxHeight: JSON.parse(res.data.data.customizationInputs).maxHeight,
          lengthMiddle: JSON.parse(res.data.data.customizationInputs)
            .lengthMiddle,
          lengthTop: JSON.parse(res.data.data.customizationInputs).lengthTop,
          lengthBottom: JSON.parse(res.data.data.customizationInputs)
            .lengthBottom,
          maxSheetLimit: JSON.parse(res.data.data.customizationInputs)
            .maxSheetLimit,
          midMatresHeight: JSON.parse(res.data.data.customizationInputs)
            .midMatresHeight,
          fittedMinRate: JSON.parse(res.data.data.customizationInputs)
            .fittedMinRate,
          fittedMaxRate: JSON.parse(res.data.data.customizationInputs)
            .fittedMaxRate,
          flatMinRate: JSON.parse(res.data.data.customizationInputs)
            .flatMinRate,
          flatMaxRate: JSON.parse(res.data.data.customizationInputs)
            .flatMaxRate,
        });
      }

      SetGetImage(res.data.data.productImage);

      try {
        setData({
          ...data,
          ...res.data.data,
          tags: JSON.parse(res.data.data.tags).split(","),
        });
      } catch {}
      setSpec(true);
    });
  }, []);
  console.log("custom inputs", customizationInputs.minLength);

  // Get Tax
  useEffect(() => {
    const getTax = () => {
      api
        .post("/admin/shop/tax")
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data != "No Taxes found") {
            setTaxlist(
              res.data.data.map((data) => {
                return {
                  label: data.taxName,
                  value: data.id,
                  taxPercentage: data.taxPercentage,
                  taxId: data.id,
                };
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTax();
  }, [props.taxRender]);

  //------------- SPEC --------------------
  useEffect(() => {
    if (spec) {
      setLoading(!loading);
    }
  }, [spec]);

  useEffect(() => {
    if (convertedImage) SetGetImage(URL.createObjectURL(convertedImage));
  }, [convertedImage]);

  function setTags(e) {
    console.log(e);
    setData({ ...data, tags: e });
  }

  function handleChange(e) {
    if (e.label) {
      console.log(data);
      setData({
        ...data,
        categoryName: e.label,
        categoryId: e.id,
        tax: e.tax,
        age: e.age,
      });
      setRender(!render);
    } else setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleImageChange(e) {
    // console.log(e);
    setData({ ...data, image: e });
  }

  function handleSubmit(e) {
    setLoad(true);
    e.preventDefault();
    e.currentTarget.reset();
    const final = {
      categoryName: data.categoryName,
      categoryId: data.categoryId,
      productName: data.productName,
      age: data.age,
      image: convertedImage,
      // productSpecification: JSON.stringify(data.productSpecification),
      customizationInputs: JSON.stringify(customizationInputs),
      moreInfo: data.moreInfo,
      tags: data.tags,
      productDescription: data.productDescription,
      productId,
    };
    const formdata = new FormData();
    Object.entries(final).map((data) => {
      formdata.append(data[0], data[1]);
    });
    console.log("finall", final);
    api
      .post("/admin/product/updateProduct", formdata)
      .then((res) => {
        setLoad(false);
        console.log(res.data.data);
        notify(res.data.data, "success");
        setData({ productDescription: "", tags: [], moreInfo: "" });
        setSubmitRender(!submitRender);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkArray(data) {
    // console.log('checkArray',data[0]);
    if (Array.isArray(data)) return data;
    else return [];
  }
  console.log("setData", data);
  console.log("cateName", loading);
  console.log("value", data);

  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      {loading ? (
        " "
      ) : (
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Product</h1>
                </div>
              </div>

              <div className="col-12">
                <section className="box ">
                  <header className="panel_header">
                    <h2 className="title float-left">Update Product</h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                        <Form onSubmit={handleSubmit}>
                          <FormGroup>
                            <Label htmlFor="exampleSelect3">
                              Category Name
                            </Label>
                            <Select
                              name="cateName"
                              defaultValue={{ label: data.categoryName }}
                              className="select2"
                              options={catelist}
                              styles={customStyles}
                              onChange={handleChange}
                              key={spec}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="exampleEmail7">Product Name</Label>
                            <Input
                              type="text"
                              value={data.productName}
                              name="productName"
                              id="exampleEmail7"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="exampleText">
                              Tags{" "}
                              <span
                                style={{ color: "#ffb74d", fontWeight: "bold" }}
                              >
                                (Keywords used for searching products in
                                website)
                              </span>
                            </Label>
                            <div className="form-group">
                              <div className="controls">
                                <TagsInput
                                  value={data.tags}
                                  name="tags"
                                  onChange={setTags}
                                  onlyUnique={true}
                                />
                              </div>
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="exampleFile">Product Image</Label>
                            <br />
                            <img
                              src={process.env.REACT_APP_BASE_URL + GetImage}
                              width={130}
                              height={130}
                              alt="img"
                            />
                            <Cropper
                              imageStore={setConvertedImage}
                              image={convertedImage}
                              aspectRatio={1 / 1}
                              reset={submitRender}
                            />
                          </FormGroup>
                          <Label htmlFor="exampleText">Is Customize?</Label>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="customize"
                                value="yes"
                                style={{
                                  marginTop: "-10px",
                                  marginBottom: "10px",
                                }}
                                onChange={handle}
                              />{" "}
                              Yes
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="customize"
                                value="no"
                                style={{ marginTop: "-10px" }}
                                onChange={handle}
                              />{" "}
                              No
                            </Label>
                          </FormGroup>

                          {data.customize === "yes" ? (
                            <FormGroup>
                              <Label htmlFor="exampleText">
                                Product Formula
                              </Label>
                              <Select
                                name="productFormula"
                                defaultValue={{
                                  label: (
                                    <SwitchCase1 value={data.productFormula} />
                                  ),
                                }}
                                className="select2"
                                options={productFormula}
                                styles={customStyles}
                                onChange={handleChanges}
                              />
                            </FormGroup>
                          ) : (
                            false
                          )}
                          {data.customize === "yes" ? (
                            <SwitchCase value={data.productFormula} />
                          ) : (
                            false
                          )}

                          <FormGroup>
                            <Label htmlFor="exampleEmail7">Age : </Label>
                            <span style={{ color: "red" }}> Add Max Age</span>
                            <Input
                              type="text"
                              name="age"
                              value={data.productAgegroup}
                              id="exampleEmail7"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="exampleSelect3">Tax Name</Label>
                            <Select
                              name="taxName"
                              className="select2"
                              options={taxlist}
                              styles={customStyles}
                              onChange={handleChanged}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label htmlFor="exampleText">
                              Product Description
                            </Label>
                            <Input
                              type="textarea"
                              value={data.productDescription}
                              onChange={handleChange}
                              name="productDescription"
                              id="exampleText"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="exampleText">
                              More Information{" "}
                              <span
                                style={{ color: "#ffb74d", fontWeight: "bold" }}
                              >
                                {" "}
                                (Optional)
                              </span>
                            </Label>
                            <Input
                              type="textarea"
                              value={data.moreInfo}
                              onChange={handleChange}
                              name="moreInfo"
                            />
                          </FormGroup>
                          {/* ---------------------- TECH SPEC-------------------------------------------- 
                          {checkArray(valueList) ? (
                            <header className="panel_header">
                              <h2
                                className="title float-left"
                                style={{ paddingLeft: "0px" }}
                              >
                                Add Technical specifications
                              </h2>
                            </header>
                          ) : (
                            ""
                          )}

                          <div className="row">
                            {checkArray(data.productSpecification).map(
                              (info, i) => (
                                <div className="col-lg-6">
                                  <FormGroup>
                                    <Label
                                      htmlFor="exampleText"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {info.productTitle}
                                    </Label>
                                    <Input
                                      value={info.productAnswer}
                                      type="text"
                                      name="specificationValue"
                                      onChange={(e) => {
                                        console.log(
                                          "console",
                                          data.productSpecification
                                        );
                                        data.productSpecification[
                                          i
                                        ].productAnswer = e.target.value;
                                        setRenderScreen(!renderScreen);
                                      }}
                                    />
                                  </FormGroup>
                                </div>
                              )
                            )}
                          </div> */}

                          <FormGroup style={{ marginBottom: "0px" }}>
                            <button type="submit" className="btn btn-primary">
                              {load ? (
                                <span>Loading...</span>
                              ) : (
                                <span>Update</span>
                              )}
                            </button>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AddProduct;
