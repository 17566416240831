import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { } from "components";
import { useParams } from "react-router-dom";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import ColorPicker from "rc-color-picker";

const UpdateStock = () => {
  const param = useParams();
  const [load, setLoad] = useState(false);
  const [variantColor, setVariantColor] = useState("");
  const [availableStock, setAvailableStock] = useState("");
  const variantId = param.id;
  const [variantcount, setVariantCount] = useState(0);
  const notiRef = useRef();
  const [data, setData] = useState({
    alternateStock: [],
    availableStock: [],
    variantImage: [],
    // variantColor: [],
  });
  const parse = (data) => {
    try {
      return Array.isArray(JSON.parse(data)) ? JSON.parse(data) : []
    }
    catch {
      return [];
    }
  }

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };

    notiRef.current.notificationAlert(options);
    var redirect =
      type === "success"
        ? setTimeout(() => (window.location.href = "/stock"), 1300)
        : "";
  }

  useEffect(() => {
    api
      .post("/admin/product/", { variantId: variantId })
      .then((res) => {
        setVariantColor(res.data.data[0].variantColor);
        console.log("test", res.data.data[0].availableStock);
        setAvailableStock(res.data.data[0].availableStock)
        setData(
          res.data.data.map((info) => ({
            ...info,
            availableStock: parse(info.availableStock),
            alternateStock: parse(info.alternateStock),
            variantImage: parse(info.variantImage),
            variantColor: parse(info.variantColor),
          }))[0]
        );
        //   console.log("image", JSON.parse(res.data.data[0].variantImage));
      })
      .catch((err) => {
        console.log(err);
        notify(err, "danger");
      });
  }, []);

  function handleChange(e,) {
    console.log(e);
    data[e.target.name] = Number(e.target.value);
  }

  function handleSubmit(e) {
    setLoad(true);
    e.preventDefault();
    e.currentTarget.reset();
    setData({ ...data, variantId: variantId })
    const final = {
      alternateStock: data.alternateStock,
      availableStock: data.availableStock,
      variantId: variantId,
    }
    console.log('submitted', final);
    api.post("/admin/product/updateStock", final).then((res) => {
      console.log(res);
      setLoad(false);
      notify(res.data.data, "success");
    })
      .catch(err => {
        setLoad(false);
        console.log(err);
      })
  }


  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Stock Management</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Update Stock</h2>
                </header>
                <div className="content-body">

                  <div class="" style={{display: 'flex'}}>
                    {Array.from(
                      { length: data.variantImage.length },
                      (e, i) => (
                        <>
                        {/* <div className="col-lg-3"> */}

                          <FormGroup>
                            <div class="px-3">

                            <img
                              src={process.env.REACT_APP_BASE_URL + data.variantImage[i]}
                              width={100}
                              height={100}
                              alt="img"
                              />
                            </div>
                          </FormGroup>
                              {/* </div> */}
                        </>
                      )
                    )}
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">

                      <FormGroup>
                        <ColorPicker
                          animation="slide"
                          color={variantColor}
                          className="color-css"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleEmail7">Existing Stock : {availableStock?availableStock:"0"}</Label>
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleEmail7">New Stock</Label>
                        <Input
                          type="number"
                          name="availableStock"
                          onChange={(e) => handleChange(e,)}
                          // value={data.categoryName}
                          className="update-cate"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleEmail7">
                          Alternate Stock
                        </Label>
                        <Input
                          type="number"
                          name="alternateStock"
                          onChange={(e) => handleChange(e,)}
                        // value={data.taxPercentage}
                        />
                      </FormGroup>
                      <Form onSubmit={handleSubmit}>
                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn btn-primary"
                          >
                            {load ? <span>Loading</span> : <span>Update</span>}
                          </button>
                        </FormGroup>
                      </Form>

                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateStock;
