
import React from "react";
import Swal from "sweetalert2";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import {} from "components";
import RecommendProductList from "../Dashboard/RecommendProductList"
import { Line, Bar } from "react-chartjs-2";

import {
  dashboardAllProductsChart,
  dashboardAllProductsChart1,
  dashboardAllProductsChart2,
  dashboardAllProductsChart6,
  dashboardAllProductsChart3,
} from "variables/general/dashboard-charts-9.jsx";

//import styles from 'jvectormap/jquery-jvectormap.css'
import { VectorMap } from "react-jvectormap";
import { useEffect } from "react";
import api from "../../../Axios";
import { useState,useRef } from "react";
import Select from "react-select";
// import { customStyles } from "../../assets/css/CustomStyles";
import { customStyles } from "../../../assets/css/CustomStyles";



const Dashboard9 = () => {
  const notiRef = useRef();
  const [render, setRender] = useState(false);
  const [load, setLoad] = useState(false);
  const [variantcount, setVariantCount] = useState(1);
  const [data, setData] = useState({
    activeCustomer:0,
    terminatedCustomer:0,
    inactiveCustomer:0,
    category:0,
    product:0,
    orders:0
  })
  const [data1, setData1] = useState({
   productId:"",
   products:""
  })
  const [productList, setProductList] = useState([]);
  useEffect(()=>{
    api.get("/admin/shop/dashboard").then(res=>{
      // console.log(res.data);
      setData(res.data.data)
    })
    .catch(err=>{
      console.log(err);
    })

    // api.get("/admin/auth/session").then(res=>{
    //   // console.log(res.data);
    //   setData(res.data.data)
    // })
    // .catch(err=>{
    //   console.log(err);
    // })
  },[])

  // function notify(msg, type) {
  //   var type = type;
  //   var options = {};
  //   options = {
  //     place: "tc",
  //     message: (
  //       <div className="notification-msg">
  //         <div className="text-center">{msg}</div>
  //       </div>
  //     ),
  //     type: type,
  //     icon: "",
  //     autoDismiss: type == "danger" ? 5 : 3,
  //   };
  //   notiRef.current.notificationAlert(options);
  // }
    //---------- PRODUCT DETAILS --------
    useEffect(() => {
      api
        .get("/admin/product/getProduct/" + null)
        .then((res) => {
          if (Array.isArray(res.data.data))
            setProductList(
              res.data.data.map((data) => {
                return {
                  label: data.productName,
                  productId: data.id,
                  tax: data.tax,
                };
              })
            );
        })
        .catch((error) => console.log("error", error));
    }, []);
    function handleChange(e) {
      if (e.label) {
        // console.log(e);
        setData1({
          ...data1,
          productId: e.productId,
          // productName: e.label,
          // tax: e.tax,
        });
      } else setData1({ ...data1, [e.target.name]: e.target.value });
    }
    function handleChange1(e) {
      if (e.label) {
        // console.log(e);
        setData1({
          ...data1,
          products: e.productId,
          // productName: e.label,
          // tax: e.tax,
        });
      } else setData1({ ...data1, [e.target.name]: e.target.value });
    }
    function handSubmit(e) {
      setLoad(true);
      e.preventDefault();
      e.currentTarget.reset();
      // console.log("data", data);
      const formdata = new FormData();
      Object.entries(data1).map((dat) => {
        formdata.append(dat[0], dat[1]);
      });
      api
        .post("/admin/product/addRecommendProduct", data1)
        .then((res) => {
          setLoad(false);
          // console.log(res.data.data);
          Swal.fire({
            icon: "success",
            timer: 4000,
            buttons: false,
            showConfirmButton: false,
            text: res.data.data,
          })
          // notify(res.data.data, "success");
          setRender(!render);
        
          // setVariantCount(1);
        })
        .catch((err) => {
          setLoad(false);
          Swal.fire({
            icon: "error",
            timer: 4000,
            buttons: false,
            showConfirmButton: false,
            text: err.response.data.error.message,
          });
          console.log(err);
          // notify(err, "danger");
        });
    }
  return (
    <div>
    
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Dashboard</h1>
              </div>
            </div>

            <div className="row margin-0">
              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox">
                  <div className="widdata">
                    <i className="widicon i-user-following icon-lg icon-primary"></i>
                    <h3 className="widtitle" style={{whiteSpace:'pre-line'}}>Active Customers</h3>
                    <p className="widtag">{data.activeCustomer}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox  ">
                  <div className="widdata">
                    <i className="widicon i-user-following icon-lg icon-white"></i>
                    <h3 className="widtitle" style={{whiteSpace:'pre-line'}}>In-Active Customers</h3>
                    <p className="widtag">{data.inactiveCustomer}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox">
                  <div className="widdata">
                    <i className="widicon i-user-unfollow icon-lg icon-primary"></i>
                    <h3 className="widtitle" style={{whiteSpace:'pre-line'}}>Terminated Customers</h3>
                    <p className="widtag">{data.terminatedCustomer}</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox ">
                  <div className="widdata">
                    <i className="widicon i-grid icon-lg icon-white"></i>
                    <h3 className="widtitle">Categories</h3>
                    <p className="widtag">{data.category}</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox">
                  <div className="widdata">
                    <i className="widicon i-book-open icon-lg icon-primary"></i>
                    <h3 className="widtitle">Products</h3>
                    <p className="widtag">{data.product}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="db_box iconbox ">
                  <div className="widdata">
                    <i className="widicon i-basket icon-lg icon-white"></i>
                    <h3 className="widtitle">Orders</h3>
                    <p className="widtag">{data.orders}</p>
                  </div>
                </div>
              </div>

            </div>

            
          </Col>
        </Row>

        <Row>
        <Col xs={12} md={12}>
              {/* <div className="col-l2"> */}
              <section className="box " style={{marginLeft:"15px",marginRight:"15px"}}>
              <header className="panel_header">
              <h2 className="title float-left">Add Recommended Product</h2>
            </header>
                <div className="content-body">
                  {/* <div className="row"> */}
                  {/* <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8"> */}
                  <Form onSubmit={ handSubmit}>
                    <div className="row" >
                      <div className="col-lg-6">
                      <FormGroup>
                          <Label htmlFor="exampleSelect3">Product Name</Label>
                          <Select
                            name="productName"
                            className="select2"
                            options={productList}
                            styles={customStyles}
                            onChange={handleChange}
                            />
                        </FormGroup>
                      </div>
                      <div className="col-lg-6">
                      <FormGroup>
                          <Label htmlFor="exampleSelect3">Recommended Items</Label>
                          <Select
                            name="productName"
                            className="select2"
                            options={productList}
                            styles={customStyles}
                            onChange={handleChange1}
                            />
                        </FormGroup>
                      </div>
                      <div className="der">
                      <FormGroup style={{ marginBottom: "0px" }}>
                        {load?<button className="btn btn-primary">Loading...</button>: <button className="btn btn-primary">Submit</button>}
                     
                      
                    </FormGroup>     
                      </div>
                    </div>
                    </Form>
                


                    {/* </div> */}
                  {/* </div> */}
                 
                  </div>
                            </section>
              {/* </div> */}
              </Col>
        </Row>
        <RecommendProductList render={render}/>
        {/* <Row>
        <section className="box ">
        <header className="panel_header">
              <h2 className="title float-left">Recommended Product List</h2>
              
            </header>
          </section>
        </Row> */}
      </div>
    </div>
  );
};

export default Dashboard9;
